@import url("https://fonts.googleapis.com/css?family=Abel|Caveat&display=swap");

* {
  box-sizing: border-box;
}

body {
  background-image: url("./img/background.jpg");
  background-size: cover;
  font-size: 62.5%;
}

.App {
  text-align: center;
  border: 15px solid #03383e;
  background-color: #cbf0f4;
  box-shadow: 5px 5px 5px #6b777e;
  max-width: 60%;
  margin: 5% auto;
  font-family: "Caveat", sans-serif;
  font-size: 1.5rem;
}

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.completed {
  text-decoration: line-through;
}

.timestamp {
  text-decoration: none;
  font-size: 1.3rem;
}

.todo {
  border: 1px solid #fbf203;
  background-color: #fbf203;
  box-shadow: 5px 5px 5px #6b777e;
  width: 27%;
  padding: 8% 5%;
  margin: 2%;
}

form {
  margin: 2%;
}

.newTodo {
  padding: 2%;
  margin: 2%;
  font-family: "Abel", sans-serif;
  font-size: 1.5rem;
}

.button {
  margin: 2%;
  padding: 1%;
  font-family: "Abel", sans-serif;
  border: 1px solid #03383e;
  background: #e4ebec;
  font-size: 1.3rem;
  transition: 0.3s;
}

.button:hover {
  color: #fff;
  background: #03383e;
  border: 2px solid #fff;
}
